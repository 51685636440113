/****** COLORS ******/

// Plain colors
$white: #ffffff;
$black: #000000;
$gray: #eeeef4;
$gray-dark: #e4e4e4;
$pink: #fd8f95;

// Brand Colors
$primary: #3673b9;
$secondary: #f6f6f6;
$primary-light: #ddf2fe;
$light-black: #666363;
$lighter-black: #767272;

$info: #0ca0fa;
$success: #0a9829;
$warning: #ef9610;
$danger: #f11709;

// Dark color
$info-dark: #046fb0;
$info-darker: #005384;

// Background Colors
$bg: #f7f9fe;
$bg-info: #e1f1fb;
$bg-success: #e7ffec;
$bg-warning: #fef3d8;
$bg-danger: #fbe5e4;
$bg-table: #fbfbfb;
$bg-info-light: #f4fbff;

// Border Colors
$border-info: #cae3f2;
$border-success: #c3eecd;
$border-warning: #d7d3d3;
$border-danger: #f3c0bd;
$border-gray-lighter: #f2f2f2;
$border-gray-light: #eeecec;

/****** SPACES ******/

$space: 1.25rem;

/****** FONT SIZE ******/

$font-size: 0.875rem;
$font-size-small: 0.75rem;

/****** Break Points ******/

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

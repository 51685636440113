.raas-footer {
  background-color: $bg;
  text-align: center;
  font-size: $font-size-small;

  &__text {
    padding: $space;
    margin-block-end: 0;
    margin-bottom: 0;
    line-height: 0.75rem;
  }
}

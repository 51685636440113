.transaction-header {
  padding-right: 4px;
  .title-action-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    .btn-filter {
      @extend .btn;
      @extend .btn-secondary;

      padding-left: 16px;
      padding-right: 16px;

      svg {
        margin-right: 10px;
      }
    }

    .btn-export {
      @extend .btn-filter;
      margin-left: 26px;
    }
  }

  .action-wrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button + button {
      margin-left: 20px;
    }

    .btn-filter.show {
      position: relative;

      &::after {
        left: 45%;
        z-index: 2;
        content: '';
        width: 12px;
        height: 12px;
        bottom: -16px;
        position: absolute;
        background-color: $white;
        transform: rotate(45deg);
        border-top: $border-gray-light solid 2px;
        border-left: $border-gray-light solid 2px;
      }
    }
  }

  .filter-wrapper {
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
    margin-bottom: 8px;
  }

  .filter-wrapper.show {
    height: auto;
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
    border: 2px solid $border-gray-lighter;
    border-radius: 4px;
    position: relative;
  }

  .transaction-filter {
    padding: 16px;
    font-size: $font-size-small;

    form {
      section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(var(--filter-min-section-width), 1fr));
        column-gap: var(--filter-form-grid-gap);
      }
    }
  }
}

.raas-container {
  @extend .container;

  margin: 0 auto;
  max-width: 100%;
  padding: 0;
}

.app-layout-container {
  display: flex;
  height: calc(100vh - 122px);

  .app-layout__children {
    padding: $space;
    height: calc(100vh - 122px);
    overflow-x: hidden;
    width: calc(100% - 70px);
    border-top: 1px solid $border-gray-lighter;

    @media (min-width: $md) {
      --filter-min-section-width: 45%;
    }

    @media (min-width: $lg) {
      --filter-min-section-width: 29%;
    }

    &.expanded {
      width: calc(100% - 300px);
      --filter-form-grid-gap: 20px;

      @media (min-width: $md) {
        --filter-min-section-width: 51%;
      }

      @media (min-width: $lg) {
        --filter-min-section-width: 45%;
      }

      @media (min-width: $xxl) {
        --filter-min-section-width: 29%;
      }
    }
  }

  .page-container {
    border-radius: ($space/2);

    .page-title {
      display: flex;
      align-items: center;

      &__icon {
        padding-left: $space/2;
        margin-bottom: 4px;
      }
    }
  }
}

@import 'header';
@import 'sidebar';
@import 'footer';

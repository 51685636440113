.raas-icon {
  @extend .icon;
  width: 28px;
  height: 28px;
  font-size: 15px;
  line-height: 28px;
  color: $white;
  background: $bg;
  border-radius: 50%;
  text-align: center;
}

.icon-success {
  @extend .raas-icon;
  background: $green;
}

.icon-info {
  @extend .raas-icon;
  background: $info-darker;
}

.icon-warning {
  @extend .raas-icon;
  background: $warning;
}

.icon-danger {
  @extend .raas-icon;
  background: $danger;
}

.icon-info-light {
  @extend .raas-icon;
  background: $info-dark;
}

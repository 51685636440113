.change-status-content {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
  }

  .transaction-summary {
    width: 100%;

    @media (min-width: $md) {
      width: 40%;
    }
  }

  .transaction-form {
    width: 100%;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 2px solid $border-gray-light;

    @media (min-width: $md) {
      width: 60%;
      border: unset;
      margin: unset;
      padding: unset;
      padding-left: 16px;
      border-left: 2px solid $border-gray-light;
    }
  }

  .detail-field {
    display: flex;
    flex-direction: column;

    span {
      &:first-child {
        color: $lighter-black;
      }
    }
  }

  .detail-field + .detail-field {
    padding-top: 8px;
  }
}

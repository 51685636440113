.not-found {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $gray;
  justify-content: center;
  min-height: calc(100vh - 60px);
  border-top: 1px solid $border-gray-lighter;

  &__title {
    font-size: 5rem;
  }
  &__sub-title {
    font-size: 1.5rem;
  }
}

.table td {
  max-width: 250px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.5rem 1.25rem;
  text-overflow: ellipsis;
  font-size: $font-size-small;

  &.td-detail {
    overflow: unset;
  }

  input {
    height: 1rem;
    min-width: 1rem;
    pointer-events: none;
  }
}

.table th:not(:first-child),
table td:not(:first-child) {
  min-width: 70px;
}

.table th {
  padding: 1rem 1.25rem;
  text-align: center;
  white-space: nowrap;
  color: $lighter-black;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-container {
  min-width: 100%;
  padding-right: 4px;

  .raas-card {
    @extend .card;
    border-color: $border-gray-lighter;
  }
}

.table-wrapper {
  margin-bottom: 1rem;
  overflow: auto;
  overflow-x: scroll;
  scroll-behavior: smooth;

  tbody {
    tr {
      cursor: pointer;

      &:not(.tr-expandable):nth-of-type(even) {
        background-color: $bg-table;
      }
    }
  }
}

.tr-expandable {
  padding: 0;

  td {
    padding: 0;
  }
}

.expandable-row {
  border-left: 5px solid transparent;

  &-wrapper {
    padding: 16px;

    .action-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 12px;

      .detail-link {
        color: $white;
      }

      a:hover {
        text-decoration: none;
      }

      .dropdown + a {
        margin-left: 30px;
      }
    }

    .detail-wrapper {
      background-color: $bg-table;
      border: 1px solid $border-gray-light;
      padding: 16px;
      border-radius: 4px;
    }

    .detail {
      display: flex;
      text-align: left;
      justify-content: flex-start;

      .detail-title {
        width: 10%;
      }

      .detail-field-wrapper {
        display: flex;
        margin-left: 12px;
        width: calc(90% - 28px);

        .detail-col + .detail-col {
          margin-left: 12px;
        }
      }
    }

    .detail + .detail {
      padding-top: 42px;
    }
  }

  &.success {
    border-left: 5px solid $success;
  }
  &.warning {
    border-left: 5px solid $warning;
  }
  &.info {
    border-left: 5px solid $info;
  }
  &.danger {
    border-left: 5px solid $danger;
  }
}

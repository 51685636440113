.coming-soon-wrapper {
  min-height: 500px;
  background-color: $gray;

  h3 {
    padding: 32px;
    font-size: 2rem;
    text-align: center;
  }
  p {
    text-align: center;
  }

  .image-wrapper {
    padding-top: 48px;
    height: calc(100vh - 250px);

    img {
      width: 100%;
      height: 100%;
    }
  }
}

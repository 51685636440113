.report-export-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 250px);

  p,
  a {
    font-size: 1rem;
    text-align: center;
  }
}

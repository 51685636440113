.raas-header {
  background-color: $bg;
  max-height: 70px;

  .raas-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-wrapper {
      max-width: 300px;
      padding: 20px 90px;

      &.single {
        padding: 20px;
        max-width: 70px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrapper {
      width: 35px;
      height: 35px;
      display: flex;
      cursor: pointer;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      background-color: $white;

      &--rounded {
        cursor: pointer;
      }
    }

    .icon-wrapper + .icon-wrapper {
      margin-left: 20px;
    }

    .icon-wrapper + .icon-wrapper--rounded {
      margin-left: 20px;
      border-radius: 50%;
    }

    .show {
      .btn-transparent.dropdown-toggle {
        &:focus,
        &.focus {
          border-color: transparent;
          box-shadow: none;
        }
      }
    }
  }

  &__dropdown {
    &--button {
      height: 40px;
      display: flex;
      min-width: 120px;
      margin-left: 16px;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-info !important;

      .user-name {
        padding-right: 8px;
        padding-left: 8px;
        font-size: $font-size;
        max-width: 84px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.timezone {
        min-width: 150px;
        position: relative;
      }

      &::after {
        display: none;
      }

      img {
        height: 25px;
      }
    }
  }

  &__dropdown--item {
    padding: 4px 10px;
    font-size: $font-size;

    svg {
      margin-right: 8px;
    }
    a {
      font-size: unset;
      padding: unset;
    }

    &:hover,
    &:active {
      border-radius: 4px;
      background-color: $primary-light;
    }
  }

  .user-info {
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }
}

.transaction-detail-wrapper {
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 36px;
  }

  .action-navigation {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  .back-link {
    color: unset;
    svg {
      margin-right: 10px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .action-wrapper {
    display: flex;

    button {
      padding-left: 16px;
      padding-right: 16px;
      border-color: $border-warning;
    }

    .btn-raas-primary {
      svg {
        margin-right: 10px;
      }
    }

    .dropdown + button {
      margin-left: 20px;
    }
  }

  .affiliate-info-wrapper {
    background-color: $bg-table;
    border: 1px solid $border-gray-light;
    padding: 16px;
    border-radius: 4px;
  }

  .detail-field-wrapper {
    display: flex;
    padding-left: 12px;
    height: 100%;
    width: calc(100% - 28px);

    .detail-col + .detail-col {
      padding-left: 12px;
    }

    &.expandable {
      max-height: 260px;
      overflow: hidden;
    }

    &.expandable.expanded {
      max-height: 100%;
      overflow: visible;
    }
  }

  .transaction-info-wrapper {
    background-color: $bg-table;
    border: 1px solid $border-gray-light;
    padding: 16px;
    border-radius: 4px;
    margin: 1.5rem 0rem;
    position: relative;

    .expand-icon-wrapper {
      right: 24px;
      width: 24px;
      height: 24px;
      bottom: -10px;
      position: absolute;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
      background-color: $gray;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .expandable {
      max-height: 250px;
      overflow: hidden;

      &.expanded {
        max-height: 100%;
        overflow: visible;
      }
    }
  }

  .transaction-info-title {
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    align-items: center;
    color: $light-black;
    margin-bottom: 20px;

    svg {
      margin-left: 0.3rem;
    }
  }
}

.transaction-events-wrapper {
  background-color: $bg-table;
  border: 1px solid $border-gray-light;
  padding: 28px;
  border-radius: 4px;
  margin: 1rem 0rem;
}

.transaction-history-wrapper {
  max-width: 50%;

  .header {
    padding-bottom: 20px;
    color: $light-black;
    font-weight: 600;

    svg {
      margin-left: 10px;
    }
  }

  .transaction-history {
    position: relative;
    min-height: 50px;

    &:last-child {
      min-height: unset;
    }

    .icon-type-wrapper {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
      }

      .history-status {
        .history-time {
          color: $lighter-black;
          font-size: $font-size-small;
        }
      }
    }

    &::after {
      top: 28px;
      bottom: 0;
      left: 12px;
      width: 4px;
      content: '';
      position: absolute;
      border-radius: 2px;
      height: calc(100% - 23px);
      background-color: $gray-dark;
      transition: opacity 0.3s ease-in-out;
    }

    .hold-reason-wrapper {
      padding-left: 38px;
      font-size: $font-size-small;
    }
  }
  .transaction-history + .transaction-history {
    padding-bottom: 16px;
  }
}

.copy-to-clipboard {
  left: 0;
  z-index: 2;
  width: 100%;
  bottom: -42px;
  padding: 0.5rem;
  min-width: 200px;
  position: absolute;
  border-radius: 4px;
  color: $white !important;
  background-color: $lighter-black;
}

.raas-input-group {
  @extend .form-group;

  &.inline {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: center;
    }

    label {
      min-width: var(--inline-label-min-width);
    }

    input,
    select,
    .react-datepicker-wrapper {
      width: 100%;
      font-size: $font-size-small;
    }
  }
}

.form-check-group {
  .form-check {
    display: flex;
    align-items: center;

    .form-check-input {
      margin-top: unset;
    }
  }
}

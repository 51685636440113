.raas-modal {
  &.show {
    .modal-dialog {
      .modal-content {
        padding: 8px;
        border-radius: 16px;
        background-color: $bg;
        font-size: $font-size-small;

        .modal-header {
          font-size: 1rem;
          border-bottom: none;
          .modal-header-container {
            text-align: center;
            width: 100%;
            small {
              color: $lighter-black;
            }
          }
        }

        .form-control {
          font-size: $font-size-small;
          line-height: 12px;
        }

        .text-area-wrapper {
          .form-control {
            min-height: 50px;
          }

          .invalid-feedback {
            font-size: $font-size;
            padding-top: 8px;
          }
        }

        .action-wrapper {
          padding: 1rem;
          display: flex;
          justify-content: flex-end;
        }

        .success-message-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            padding-top: 8px;
            font-size: $font-size;
          }

          .confirm-message{
            padding: 8px 30px;
          }

          .confirmation-button {
            display: flex;
            margin-top: 20px;
            margin-bottom: 50px;
            button:first-child {
              margin-right: 20px;
            }
            .no-button {
              padding: 5px;
              width: 80px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid $primary;
              color: $primary;

              &:hover {
                box-shadow: 0px 2px 4px $primary;
              }
            }

            .yes-button {
              padding: 5px;
              width: 80px;
              background-color: $primary;
              color: white;
              border-radius: 4px;
              border: 1px solid $primary;

              &:hover {
                box-shadow: 0px 2px 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}

.raas-dropdown-toggle {
  @extend .dropdown-toggle;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding-right: 4px;
  }

  &::after {
    opacity: 0;
    display: none;
  }
}

.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-repeat: no-repeat;
  grid-template-columns: auto auto;
  background-position: center center;
  background-image: url('../../images/login-bg.svg');

  .logo-container {
    width: 40%;

    .logo-wrapper {
      width: 329px;
      height: 130px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .login-form-wrapper {
    width: 45%;
    display: flex;
    min-height: 500px;
    margin-top: -60px;
    align-items: center;
    font-size: $font-size;
    flex-direction: column;
    justify-content: center;

    & > * {
      width: 100%;
      max-width: 420px;
    }

    .title {
      color: $white;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      margin-bottom: 48px;
    }

    label {
      color: $white;
    }

    input {
      font-size: unset;
      line-height: $font-size;
    }

    .action-wrapper {
      margin-top: 40px;

      button {
        width: 100%;
        background-color: $primary;
      }
    }

    .error {
      color: $red;
      text-align: right;
    }
  }
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: $font-size;
}

.login-modal {
  background: white;
  width: 500px;
  margin: auto;
}

.shadow-line {
  height: 5px;
  box-shadow: 0px 2px 4px #eaeaea;
}

.p-sticky {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 30px;
}

.btn-primary {
  background-color: #0060a9;
}

.btn-primary:hover {
  background-color: #217abe;
}

// FIXME: Remove code above this line
// Utility classes should be generated with mixin

.rotate-180 {
  transform: rotate(180deg);
}

.icon-caret {
  transition: all 4s;
}

.hidden {
  height: 0;
  width: 0;
  transform: translateX(-10000px);
}

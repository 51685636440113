// FIXME: Refactor to use specific form classes and use in modular approach

.raas-select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
}

.radio__label {
  padding: 5px;
}

.radio__title-label {
  padding: 5px;
  font-weight: bold;
}

.app-sidebar {
  min-width: 70px;
  background-color: $bg;
  height: calc(100vh - 70px);

  .expand-icon {
    margin: 8px 0;
    text-align: center;
  }

  .app-sidebar__item {
    display: flex;
    margin: 5px 10px;
    border-radius: 6px;
    align-items: center;
    padding: 0.85rem 1rem;
    justify-content: space-between;

    &.active {
      color: $white;
      background-color: $primary;
    }

    &:hover:not(.active) {
      background-color: $primary-light;
    }

    &-caret {
      display: none;
    }

    &-content {
      display: flex;
      align-items: center;

      &__label {
        display: none;
      }
    }
  }

  &.expanded {
    min-width: 300px;

    .expand-icon {
      margin: 8px 25px;
      text-align: right;
    }

    .app-sidebar__item {
      &-caret {
        display: block;
      }

      &-content {
        &__icon {
          padding-right: 1rem;
        }

        &__label {
          display: block;
        }
      }
    }
  }
}

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
);

@import '~bootstrap/scss/bootstrap';

:root {
  --inline-label-min-width: 150px;
  --filter-form-grid-gap: 40px;
  --filter-min-section-width: 100%;
  --display-filter-triangle: none;
}

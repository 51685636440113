.success-chip-wrapper {
  width: 100px;
  padding: 16px;
  display: flex;
  height: 100px;
  color: $success;
  border-radius: 50%;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
  background-color: $bg-info-light;
  border: 1px solid $border-success;

  svg {
    width: 60px;
    height: 60px;
  }
}

.error-chip-wrapper {
  @extend .success-chip-wrapper;

  color: $danger;
  background-color: $bg-danger;
  border: 1px solid $border-danger;
}

.raas-modal.show .modal-dialog .modal-content .success-message-wrapper p {
  text-align: center;
}

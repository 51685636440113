.detail-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33.3%;

  .detail-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    &__name {
      color: $lighter-black;
      font-size: $font-size-small;
    }

    &__value {
      position: relative;

      &-text {
        color: $black;
        font-size: $font-size;
        text-align: left;
        white-space: break-spaces;

        user-select: all;
        -webkit-user-select: all;
        -moz-user-select: all;
        -ms-user-select: all;
      }
    }

    &__copy-icon {
      display: inline-block;
      vertical-align: text-top;
      font-size: $font-size;
      margin-left: 0.3rem;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }

  .detail-field + .detail-field {
    padding-top: 16px;
  }
}

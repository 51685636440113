.btn-transparent {
  &:focus,
  &.focus {
    box-shadow: none;
    border-color: transparent;
  }

  &.dropdown-toggle {
    box-shadow: none;
    border-color: transparent;
  }
}

.btn-status-info {
  color: $info;
  background: $bg-info;
  border: 1px solid $border-info;
}

.btn-status-success {
  color: $success;
  background: $bg-success;
  border: 1px solid $border-success;
}

.btn-status-warning {
  color: $warning;
  background: $bg-warning;
  border: 1px solid $border-warning;
}

.btn-status-danger {
  color: $danger;
  background: $bg-danger;
  border: 1px solid $border-danger;
}

.btn-status {
  min-width: 82px;
  height: 28px;
  font-size: 12px;
  line-height: 12px;
  padding: 6px 10px;
  border-radius: 4px;
  font-weight: 500;

  &.info {
    @extend .btn-status-info;
  }

  &.success {
    @extend .btn-status-success;
  }

  &.warning {
    @extend .btn-status-warning;
  }

  &.danger {
    @extend .btn-status-danger;
  }
}

.btn-rounded {
  all: unset;
  @extend .btn;
  border-radius: 20px;
  height: 30px;
  padding: 0 14px;
  line-height: 14px;
  font-size: $font-size-small;

  &:disabled,
  &:hover:disabled {
    opacity: 0.8;

    svg {
      pointer-events: none;
    }
  }
}

.btn-rounded-primary {
  @extend .btn-primary;
  @extend .btn-rounded;

  svg {
    margin-right: 12px;
  }

  color: $white;
  background-color: $primary;
}

.btn-raas {
  @extend .btn;
  font-size: 0.875rem;
  padding-left: 32px;
  padding-right: 32px;
}

.btn-raas-primary {
  @extend .btn-primary;
  @extend .btn-raas;
}

.btn-raas-secondary {
  @extend .btn-secondary;
  @extend .btn-raas;
}

.btn-raas-info {
  @extend .btn-outline-primary;
  @extend .btn-raas;
}

.btn-raas-outline-info {
  background: $bg-info-light;
  border: 1px solid $border-info;
  color: $info-dark;

  svg {
    margin-left: 12px;
  }

  &:hover:disabled {
    color: $info-dark;
  }

  &:active,
  &.active,
  &:hover:not(:disabled) {
    color: $info-dark;
    border: 1px solid $border-info;
    background-color: $bg-info;
  }
}

.btn-raas-rounded-outline-info {
  @extend .btn-rounded;
  @extend .btn-raas-outline-info;
}

.raas-pagination {
  @extend .pagination;

  .page-item {
    cursor: pointer;

    .page-link {
      border: 1px solid transparent;
      margin: 0 1px;

      &:hover {
        border: 1px solid $border-info;
        border-radius: 4px;
      }

      &.bordered {
        border: 1px solid $border-info;
        border-radius: 4px;
      }

      &.active {
        z-index: 2;
        color: $white;
        border-radius: 4px;
        border: 1px solid $info;
        background-color: $info;
      }

      &--text {
        @extend .page-link;
        color: $lighter-black;
        background-color: transparent;

        &:hover {
          color: unset;
          background-color: unset;
          border: 1px solid transparent;
        }
      }

      &.page-size {
        min-width: 42px;
        max-width: 70px;
        padding: 5px 8px;
      }
    }

    &.disabled {
      pointer-events: none;

      .page-link {
        border: 1px solid transparent;
      }
    }
  }
}
